.alert_tips {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #505363;
  line-height: 24px;
  .dot {
    width: 5px;
    height: 5px;
    background: #535353;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
}

// 策略详情
.icon {
  color: #9da5b2 !important;
  margin-left: 8px;
  font-size: 14px;
}

.update_time {
  color: #9da5b2;
  float: right;
  font-size: 12px;
  font-weight: normal;
}

.desc {
  & > .desc_title {
    font-size: 16px;
    font-weight: bold;
    color: #21252e;
    line-height: 16px;
    margin: 16px 0;
  }
  & > .desc_content {
    font-size: 12px;
    font-weight: 400;
    color: #21252e;
  }
}

.content {
  margin-bottom: 16px;
  overflow: hidden;
  transition: max-height 0.5s;
}
.action {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: #00b259;
  cursor: pointer;
}

// 创建策略
.create-policy, .create-config {
  width: 478px;
  margin: 0 auto;
}

.divider {
  width: calc(~'100% + 48px') !important;
  margin: 24px -24px !important;
}

.create-tips {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8c97b2;
  line-height: 20px;
  margin-bottom: 24px;
}

