.details {
  .title {
    font-size: 16px;
    font-weight: bold;
    color: #21252E;
    line-height: 16px;
  }
  .edit {
    margin-left: 8px;
    font-size: 16px;
    color: #9DA5B2;
  }
  :global {
    .ant-descriptions-title {
      margin-bottom: 16px;
    }
    .ant-descriptions-item-label {
      font-size: 12px;
      font-weight: 400;
      color: #21252E;
      line-height: 12px;
    }
    .ant-descriptions-item-content {
      flex: 1;
      font-size: 12px;
      font-weight: 400;
      color: #505363;
      line-height: 12px;
    }
    .ant-descriptions-row>th, .ant-descriptions-row>td {
      padding-bottom: 8px;
    }
    .ant-descriptions-row {
      line-height: 12px;
    }
    .ant-descriptions-row:last-child>.ant-descriptions-item{
      padding-bottom: 0;
    }
  }
}
