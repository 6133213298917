.modal_box,
.modal_box_padding {
  text-align: center;
  .modal_icon {
    color: #ff6417;
    font-size: 48px;
    margin: 24px 0;
  }
  .modal_title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #21252e;
    line-height: 22px;
    text-shadow: 0px 4px 16px rgba(33, 37, 46, 0.12);
  }
  .modal_des {
    margin: 10px 0 0;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #505363;
    line-height: 16px;
    text-shadow: 0px 4px 16px rgba(33, 37, 46, 0.12);
  }
  .modal_btn_center {
    margin: 32px 0 8px;
  }
  .modal_btn {
    margin: 32px 0 8px;
    text-align: right;
  }
  .modal_form {
    margin-top: 16px;
    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  :global {
    .ant-modal-title {
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #21252e;
      line-height: 20px;
    }
    .ant-modal-header {
      border-bottom: none;
      padding: 32px 24px 8px;
    }
  }
}

.modal_box_padding {
  :global {
    .ant-modal-body {
      padding: 24px 40px;
    }
    .ant-modal-header {
      border-bottom: none;
      padding: 32px 40px 8px;
    }
  }
  h3 {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #21252e;
    line-height: 20px;
    margin: 0;
  }
  .modal_title_tips {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9ea5b2;
    margin-top: 8px;
  }
}

