.authority-title {
  display: inline-block;
  .authority-label {
    height: 18px;
    margin-left: 16px;
    font-size: 12px;
    padding: 0 8px;
    border-radius: 10px;
    border: 1px solid;
    line-height: 16px;
  }
  .internal {
    color: #00b259;
    border-color: #afe6ca;
    background: #e7f7ef;
  }
  .external {
    color: #3377ff;
    border-color: #86b0f7;
    background: #ecf2fd;
  }
}

.authority-edit {
  font-size: 16px;
  color: #9da5b2 !important;
  margin-left: 8px;
  cursor: pointer;
}

.role_id {
  font-size: 12px;
  font-weight: 400;
  color: #505363;
  line-height: 12px;
  margin-bottom: 16px;
}
.role_key {
  color: #9ea5b2;
}

.description {
  .description_title {
    font-size: 14px;
    font-weight: 500;
    color: #21252e;
    line-height: 14px;
    margin-bottom: 12px;
  }
  .description_content {
    font-size: 12px;
    font-weight: 400;
    color: #505363;
  }
}

.edit-css {
  font-size: 12px;
  color: #9ea5b2;
}

.input_box {
  .ant-input {
    padding: 4px 8px;
    height: 30px;
    line-height: 30px;
  }
  .edit_btn {
    margin-left: 1px;
    text-align: right;
    .ant-btn {
      min-width: auto;
    }
    .ant-btn-icon-only.ant-btn-sm {
      width: 22px;
      height: 22px;
      border: none;
    }
    .ant-btn-icon-only:first-child,
    .ant-btn-icon-only:first-child:active,
    .ant-btn-icon-only:first-child:hover {
      background: #e8f7ee;
      color: #00b259;
      outline: none;
    }
    .ant-btn-icon-only:last-child,
    .ant-btn-icon-only:last-child:active,
    .ant-btn-icon-only:last-child:hover {
      background: #eef1f7;
      color: #9da5b2;
      outline: none;
    }
    button[type].ant-btn {
      padding: 0;
    }
  }
}

