.back {
  a {
    color: #00b259 !important;
    font-size: 20px;
  }

  a:active,
  a:hover {
    color: #008c46 !important;
  }
}

.resource_management {
  padding: 16px 24px;
}

.create_resource {
  padding-top: 32px;
}

.resource_details {
  padding: 16px 24px;
  .desc {
    display: flex;
    margin-top: 6px;
    &>.desc_title {
      font-size: 12px;
      font-weight: 400;
      color: #21252E;
      // line-height: 12px;
    }
    &>.desc_content {
      flex: 1;
      font-size: 12px;
      font-weight: 400;
      color: #21252E;
    }
  }
}
