.no_data_css {
  width: 100%;
  margin: auto;
  height: 321px;

  .box_position {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  span {
    color: #9EA5B2;
  }

  a {
    color: #00b259;
  }
}
