
.login-header{
  height: 56px;
  padding: 0 96px;
  display: flex;
  align-items: center;
}
.login-footer{
  color: #9EA5B2;
  font-size: 12px;
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 20px;
}
.login-content{
  position: absolute;
  top: 56px;
  bottom: 52px;
  width: 100%;
  padding: 0 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .login-wrap{
    position: absolute;
    background-color: #fff;
    width: 450px;
    height: 320px;
    padding: 20px 30px;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: rgba(0,0,0,0.1) 0 0 5px;
    .login-title-wrap{
      line-height: 28px;
      .login-title{
        font-size: 20px;
        font-weight: bold;
      }
      .login-desc{
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
}
