.rc_content {
  margin-top: 52px;
  padding: 16px 24px;
  background-color: #fff;
}

.rc_content_tab {
  margin-top: 75px;
  background-color: #fff;
  // padding: 16px 24px;
  min-height: calc(~'100vh - 120px');
  position: relative;
}

.rc_content_custom {
  margin-top: 52px;
  padding: 0 24px 16px;
}

.rc_content_bg {
  margin-top: 52px;
  background-color: #f9f9fb;
}

.rc_content_white {
  margin-top: 52px;
  background-color: #fff;
  padding: 16px 0;
}

.upper_part {
  padding: 16px 24px;
  background-color: #fff;
}

.lower_part {
  padding: 16px 24px;
  background-color: #fff;
  margin-top: 8px;
}

.title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #21252e;
  line-height: 16px;
}

.back {
  a {
    color: #00b259 !important;
    font-size: 20px;
  }

  a:active,
  a:hover {
    color: #008c46 !important;
  }
}

.delete {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #e60000;
  letter-spacing: 0.28px;
}

.pageTitle_btn {
  position: absolute;
  right: -18px;
  top: 7px;
}

.pageTitle_btn_top {
  &:extend(.pageTitle_btn);
  top: -4px;
}

.pageTitle_btn_padding {
  position: absolute;
  right: -46px;
  top: -4px;
}

.pageTitle_more {
  position: absolute;
  right: -40px;
  top: -2px;
  font-size: 20px;
}

.pageTitle_more_center {
  &:extend(.pageTitle_more);
  top: 10px;
}

.mt_32 {
  margin-top: 32px;
}

.mr_8 {
  margin-right: 8px;
}

.ml_8 {
  margin-left: 8px;
}

.mr_16 {
  margin-right: 16px;
}

.no_content {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9ea5b2;
  line-height: 12px;
}

.notice_icon {
  color: #2cbb69;
  font-size: 24px;
  vertical-align: middle;
}

.notice_des {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #21252e;
  line-height: 16px;
  margin-left: 16px;
}

.details_with_transform {
  :global {
    .ant-descriptions-row:last-child .ant-descriptions-item {
      padding-bottom: 10px;
    }
  }
}

.form_title {
  font-size: 16px;
  position: absolute;
  line-height: 16px;
  font-weight: 600;
  left: -230px;
  top: 1px;
  border-left: 4px solid #00b259;
  transition: all 0.2s;
  span {
    margin-left: 8px;
  }
}

.table_tag {
  height: 18px;
  margin-left: 8px;
  font-size: 12px;
  padding: 0 8px;
  border-radius: 10px;
  border: 1px solid #afe6ca;
  line-height: 16px;
  color: #00b259;
  background: #e7f7ef;
}

.page_title {
  color: #21252e;
  .title_text {
    display: inline-block;
    width: auto;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
    line-height: 20px;
  }
  .auth_role_edit {
    font-size: 16px;
    margin-left: 10px;
    transform: translateY(2px);
  }
  .edit_role {
    position: absolute;
    top: 16px;
    :global {
      .ant-input {
        padding: 4px 8px;
        height: 30px;
        line-height: 30px;
      }
    }
    .edit_role_btn {
      text-align: right;
      :global {
        .ant-btn {
          min-width: auto;
        }
        .ant-btn-icon-only.ant-btn-sm {
          width: 22px;
          height: 22px;
          border: none;
        }
        .ant-btn-icon-only:first-child,
        .ant-btn-icon-only:first-child:active,
        .ant-btn-icon-only:first-child:hover {
          background: #e8f7ee;
          color: #00b259;
          outline: none;
        }
        .ant-btn-icon-only:last-child,
        .ant-btn-icon-only:last-child:active,
        .ant-btn-icon-only:last-child:hover {
          background: #eef1f7;
          color: #9da5b2;
          outline: none;
        }
      }
    }
  }
  .button_box {
    position: absolute;
    top: 50%;
    right: -24px;
    transform: translateY(-50%);
  }
  .page_tabs {
    transform: translate(0, 10px);
    margin-top: -10px;
    :global {
      .ant-tabs-bar {
        margin: 0;
        border-bottom: 1px solid #fff;
      }
      .ant-tabs-nav-wrap {
        padding: 0;
      }
      .ant-tabs {
        padding: 0;
      }
      .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab {
        margin: 0 32px 0 0;
        font-weight: 400;
      }
    }
  }
  .label {
    margin-left: 8px;
    height: 20px;
    background: #e7f7ef;
    border-radius: 10px;
    border: 1px solid #afe6ca;
    font-size: 12px;
    font-weight: 400;
    color: #00b259;
    line-height: 18px;
    padding: 0 8px;
  }
}

.page_title_v2 {
  position: relative;
  :global {
    .ant-tabs {
      padding: 0;
      position: relative;
      bottom: -10px;
      font-weight: normal;
    }
    .ant-tabs-bar {
      border-bottom: none;
    }
    .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab {
      margin: 0;
    }
    .ant-tabs-ink-bar {
      bottom: 2px;
    }
  }
}

.page_button {
  position: absolute;
  right: -46px;
  top: 16px;
}

.page_button_no_tab {
  position: absolute;
  right: -22px;
  top: -3px;
}

.tag_v2 {
  height: 20px;
  margin-left: 10%;
  font-size: 12px;
  padding: 0 8px;
  line-height: 18px;
  color: #00b259;
  background: #e7f7ef;
}

.out_tag_v2 {
  &:extend(.tag_v2);
  color: #3377ff;
  background: #ecf2fd;
}

.expried_tag_v2 {
  &:extend(.tag_v2);
  color: #a4181f;
  background: #fccfd2;
}

.mark::after {
  content: '，';
}

.mark:last-child::after {
  content: '';
}
