.menu_container {
  background: #fff;
  transition: height 0.5s;
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
}
.menu_container {
  .container {
    height: calc(~'100vh - 300px');
    border: 1px solid #f5f7fa;
    padding: 16px;
    margin-top: 16px;
    overflow-y: auto;
    .tree_tag {
      height: 18px;
      margin-left: 16px;
      font-size: 12px;
      padding: 0 8px;
      border-radius: 10px;
      border: 1px solid;
      line-height: 16px;
    }
    .green {
      color: #00b259;
      border-color: #afe6ca;
      background: #e7f7ef;
    }
    .blue {
      color: #3377ff;
      border-color: #3377ff;
      background: #3377ff1a;
    }
    .ant-tree {
      li {
        padding: 0;
        & > span {
          height: 100%;
          line-height: 50px;
        }
      }
      .ant-tree-checkbox {
        margin: 14px 8px 0 0;
      }
      .ant-tree-node-content-wrapper {
        min-height: 44px;
        line-height: 44px;
      }
      .ant-tree-switcher {
        line-height: 44px;
      }
      li ul {
        padding: 0;
        li {
          padding-left: 18px;
        }
      }
    }
    .ant-tree li span.ant-tree-checkbox {
      margin: 13px 4px 0 2px;
    }
  }
  .container::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
  }
  .container::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 6px;
    background: #ebecef;
  }
}

