.rc_form {
  width: 478px;
  margin: 0 auto;
  position: relative;
  :global {
    .ant-form-item-label {
      width: 100%;
    }
    .ant-form-item {
      margin-bottom: 24px;
      position: relative;
    }

    .ant-form-item-required::before,
    .ant-form-item-required::after,
    .ant-form-item-label > label::after {
      content: none;
    }
    .ant-input-number:focus {
      box-shadow: 0 0 0 1px #00b259;
    }
    .ant-input-number-focused {
      box-shadow: 0 0 0 1px #00b259;
    }
    // .ant-form-item-control {
    //   line-height: 36px;
    // }
  }
}

.rc_form_h {
  :global {
    .ant-tag {
      border-radius: 12px;
      background: #ebecef;
      border: 1px solid #d4d9e2;
    }
    .ant-input-sm:focus {
      border-color: #d4d9e2;
      box-shadow: none;
    }
    .ant-upload.ant-upload-drag {
      min-height: 98px;
    }
  }
}

.add_style_text {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #505363;
  letter-spacing: 0;
  line-height: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: left;
  display: inline-block;
}

.tag_tips {
  font-size: 12px;
  color: #9ea5b2;
  font-family: PingFangSC-Regular;
  line-height: 16px;
  margin-top: 8px;
  font-weight: normal;
}

.err_tips {
  &:extend(.tag_tips);
  color: #e60000;
  text-align: left;
}

.err_tips_ab {
  &:extend(.tag_tips);
  color: #e60000;
  text-align: left;
  position: absolute;
  left: 0;
  bottom: 4px;
}

.textArea_div {
  width: 478px;
  height: 90px;
  border: 1px solid #979797;
  padding: 9px 16px;
  outline-color: #00b259;
}

.site-tag-plus {
  background-color: #fff;
  font-size: 10px;
}

.newTooltip {
  :global {
    .ant-tooltip .ant-tooltip-content .ant-tooltip-inner[role='tooltip'] * {
      line-height: 20px !important;
    }
    .ant-tooltip-inner{
      width:305px;
      max-width: 305px ;
    }
  }

  .icon_color{
    color: #9EA5B2;
  }

  .tipsText {
    padding: 4.5px 11px 4.5px 6px;
    .flex {
      display: flex;
    }
  }
}

.mt-16 {
  margin-top: 16px;
}
.mr-52 {
  margin-right: 52px;
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_24 {
  margin-bottom: 24px;
}

.copyRight {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 16px;
  z-index: 7;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9ea5b2;
  line-height: 14px;
}
