@import '~antd/dist/antd.less';
@import '~@rootcloud/rc-theme/build/main/v4/less/green.less';

@font-face {
  font-family: 'DIN-LIGHT';
  src: url('./fonts/DINCondensed-Light/DINCondensed-Light.ttf') format('ttf'),
    url('./fonts/DINCondensed-Light/DINCondensed-Light.woff') format('woff'),
    url('./fonts/DINCondensed-Light/DINCondensed-Light.svg') format('svg');
}

@font-face {
  font-family: 'DIN-Regular';
  src: url('./fonts/DINCondensed-Regular/DINCondensed-Regular.ttf')
      format('ttf'),
    url('./fonts/DINCondensed-Regular/DINCondensed-Regular.woff') format('woff'),
    url('./fonts/DINCondensed-Regular/DINCondensed-Regular.svg') format('svg');
}

body {
  margin: 0;
  background-color: #f5f5f5;
}
.app-container, #root{
  position: relative;
  width: 100%;
  height: 100%;
}
.page-body{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
}
.page-container{
  padding: 20px;
  overflow: auto;
  box-sizing: border-box;
  flex: 1;
  background-color: #ffffff;
}
.breadcrumb~.page-container{
  padding-top: 64px;
}
.filter-container {
  margin-bottom: 10px;
}
.filter-item{
  display: inline-block;
  margin-right: 12px;
}
.card-container {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px;
  padding: 20px;
}
.flex-box {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.flex-space-between {
  justify-content: space-between;
}

.ant-skeleton .ant-skeleton-paragraph {
  margin-top: 0;
}

.ant-skeleton .ant-skeleton-paragraph li:first-child {
  height: 18px;
}

.ant-skeleton .ant-skeleton-paragraph li:last-child {
  margin-top: 0;
}

ol,
ul,
dl {
  margin-bottom: 0;
}

.line {
  height: 8px;
  background: #f9f9fb;
  width: calc(~'100% + 48px');
  margin: 16px -24px;
}

.page-title-box {
  display: inline-flex;
  justify-content: space-between;
  width: 98%;
}

.layout-body aside {
  z-index: 1500;
}

// 表单样式
.ant-form-horizontal {
  .ant-form-item-row {
    display: block;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
    content: '';
  }
  .ant-form-item-label > label {
    height: auto;
  }
  .ant-form-item-label > label::after {
    content: '';
  }
}

.add_style_text {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #505363;
  letter-spacing: 0;
  line-height: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: left;
  display: inline-block;
}

.err_tips {
  font-size: 12px;
  color: #e60000;
  text-align: left;
  font-family: PingFangSC-Regular;
  line-height: 16px;
  font-weight: 400;
  margin: 6px 0;
}

.tag_tips {
  &:extend(.err_tips);
  color: #9ea5b2;
  margin-top: 2px;
}

.mr-8 {
  margin-right: 8px;
}

.no-content {
  color: #9ea5b2;
  display: inline-block;
}

// modal样式
.ant-modal-header,
.ant-modal-body {
  padding: 24px 40px;
}

.ant-modal-body {
  padding-top: 0;
}

.ant-modal-title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #21252e;
  line-height: 20px;
}

.ant-modal-close-x {
  font-size: 18px !important;
}

.modal-btn-left {
  text-align: right;
}

.rc-modal {
  text-align: center;
  .rc-modal-icon {
    color: #ff6417;
    font-size: 48px;
    margin: 24px 0;
    line-height: 48px;
  }
  .rc-modal-title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #21252e;
    line-height: 22px;
    text-shadow: 0px 4px 16px rgba(33, 37, 46, 0.12);
  }
  .rc-modal-tip {
    margin: 10px 0 0;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #505363;
    line-height: 16px;
    text-shadow: 0px 4px 16px rgba(33, 37, 46, 0.12);
  }
  .rc-modal-btn {
    margin: 32px 0 8px;
  }
}

// Alert

.ant-alert-success {
  background: #e7f7ef;
  border: 1px solid #afe6ca;
}

.ant-descriptions-item .ant-descriptions-item-label {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #21252e;
  line-height: normal;
}

.ant-descriptions-item .ant-descriptions-item-content {
  color: #505363;
  overflow-wrap: break-word;
  word-break: break-all;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: normal;
}

.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 12px;
}

.ant-descriptions-row:last-child > th,
.ant-descriptions-row:last-child > td {
  padding-bottom: 0;
}

// 分页组件
.ant-pagination .ant-pagination-options-size-changer:not(.ant-select-sm) {
  top: 0px;
}

.ant-pagination
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 32px;
}

.ant-pagination
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 28px;
}

.ant-pagination-options-quick-jumper {
  height: 32px;
  line-height: 32px;
}

.ant-table.ant-table-empty table > tbody.ant-table-tbody tr:nth-child(odd) {
  background-color: #fff;
}

.ant-btn-text {
  min-width: auto !important;
  padding: 0 !important;
  height: auto !important;
  line-height: normal !important;
  color: #e60000 !important;
}

.ant-btn-text:active,
.ant-btn-text:hover,
.ant-btn-text:focus-visible {
  background: transparent !important;
  border: none !important;
  outline: none !important;
}

.ant-tabs:not(.ant-tabs-card) .ant-tabs-nav .ant-tabs-tab {
  min-width: 104px;
}

.ant-tabs-tab-btn {
  width: 100%;
}

.ant-tabs {
  width: calc(~'100% + 48px');
  margin: 0 -24px;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  margin-left: 24px;
}

.ant-btn-dangerous:not([disabled]):active {
  background-color: #d9363e;
  border-color: #fff;
  outline: #d9363e solid 1px;
}

.ant-btn-dangerous:not([disabled]),
.ant-btn-dangerous:not([disabled]):hover,
.ant-btn-dangerous:not([disabled]):focus {
  border-color: #d9363e;
  background: #d9363e;
}

.ant-btn-default:not([disabled]) {
  color: #505363;
  background-color: #fff;
  border-color: #cbd1de;
}

.ant-btn-default:not([disabled]):hover {
  color: #21252e;
  background-color: #fff;
  border-color: #21252e;
}

.ant-btn-default:not([disabled]):active {
  color: #fff;
  background-color: #505363;
  border-color: #fff;
  outline: 1px solid #505363;
}


