.rc-time-picker{
  .box{
    position: relative;
    margin-right: 20px;
    display: inline-block
  }
  .input{
    margin: 0;
    padding: 0;
    color: #505363;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum';
    display: inline-block;
    width: 100%;
    text-align: start;
  }
  .container{
    display: flex;
    position: absolute;
    top: 40px;
    left: 0;
    background: #fff;
    z-index: 99;
    min-width: 292px;
    min-height: 256px;
    box-shadow: 0 4px 10px 0 #22252b3d, 0 0 1px 0 #22252b33;
  }
  .TimeSelectPickerList{
    width:150px;
    flex: 0 0 auto;
    border-right: 1px solid rgb(235, 236, 239);
     li{
      display: flex;
      justify-content: space-between;
      padding: 8px 8px 8px 12px;
      height: 32px;
      line-height: 32px;
      border: none;
      cursor: pointer;
     }
     .TimeSelectPickerSelected{
      background: #f9f9fb;
     }
  }
  .dynamicContainer{
    width: 240px;
    padding: 11px 12px 8px;
    font-size: 12px;
    .timeContainer{
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      >span{
        width:35px
      }
      >.antInputNumber{
        min-width:112px !important;
      }
      >.antSelect{
        width: 68px;
        height: 36px
      }
    }
  }
  .dynamicButton{
    min-width: 0 !important;
    padding: 4px 16px;
    height: 28px;
    font-size: 12px;
    letter-spacing: 0;
    margin-top: 31px;
    float: right;
  }
  .staticDatePicker{
    // top: 40px !important;
    >div{
      box-shadow: none;
    }
  }
  .rangerPickerDatePanel{
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important
  }
  .staticBox{
    height: 330px;
    width: 552px
  }
}
