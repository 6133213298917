.search_select_box {
  position: relative;
  .search_box {
    height: 36px;
    border: 1px solid #d4d9e2;
    padding: 0 32px 0 12px;
    position: relative;
    cursor: pointer;
    .search_box_text {
      font-size: 14px;
      height: 34px;
      line-height: 34px;
      display: flex;
      align-items: center;
      .search_box_item {
        background: #eaedf3;
        border-radius: 10px;
        border: 1px solid #d4d9e2;
        padding: 2px 8px;
        margin-right: 8px;
        height: 18px;
        line-height: 12px;
        font-size: 12px;
      }
    }
    .search_icon_down {
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translateY(-50%);
      svg {
        transition: all 0.2s;
      }
    }
    .search_icon_up {
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translateY(-50%);
      svg {
        transition: all 0.2s;
        transform: rotate(180deg);
      }
    }
  }
  .select_box {
    position: absolute;
    top: 42px;
    left: 0;
    background: #ffffff;
    box-shadow: 0px 0px 1px 0px rgba(34, 37, 43, 0.2),
      0px 4px 10px 0px rgba(34, 37, 43, 0.24);
    z-index: 1;
    width: 100%;
    padding: 16px;
    transition: all 0.2s;
    opacity: 1;
    .search_input_box {
      height: 32px;
      width: 100%;

      .ant-input {
        height: 32px;
        padding-right: 70px;
      }
    }
    .select_list {
      max-height: 254px;
      overflow-y: scroll;
      .ant-list-item {
        padding: 0;
        margin-top: 16px;
        cursor: pointer;
      }
      .ant-list-item:first-child {
        margin-top: 0;
      }
      .ant-list {
        line-height: 14px;
      }

      .demo {
        position: absolute;
        bottom: 16px;
        width: 100%;
        text-align: center;
        .ant-spin-dot-spin {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
  .select_box_hide {
    opacity: 0;
    z-index: -1;
  }
  .menu_box {
    padding: 16px 16px 8px;
    .search_input {
      height: 32px;
    }
    .list_box {
      max-height: 296px;
      overflow: auto;
      margin-top: 8px;
      .ant-list-header,
      .ant-list-footer {
        padding-top: 16px;
        padding-bottom: 16px;
        line-height: 14px;
        border: 0;
      }
      .ant-list-split .ant-list-item {
        border: 0;
        cursor: pointer;
      }
      .list_spin {
        position: absolute;
        bottom: 50%;
        width: 100%;
        text-align: center;
      }
    }

    .list_box::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 6px;
    }
    .list_box::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 6px;
      background: #ebecef;
    }
  }
  .ant-dropdown-menu {
    padding: 8px 24px;
  }
  .ant-list-sm .ant-list-item {
    padding: 5px 16px;
  }
}

