.orgs-detail-page{
  .company_details {
    background: #fff;
    padding: 16px 24px;
  }
  
  .company_info {
    margin-bottom: 16px;
    line-height: 12px;
  }
  
  .company_text {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9ea5b2;
    line-height: 12px;
  }
  
  .company_description {
    margin-top: 16px;
    .ant-descriptions-title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #21252e;
      line-height: 14px;
    }
    .ant-descriptions-item-colon::after {
      content: '';
    }
    .ant-descriptions-item .ant-descriptions-item-label {
      line-height: 16px;
    }
  }
  
  .company_adminInfo {
    position: relative;
    h4 {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #21252e;
      line-height: 16px;
      font-weight: 600;
      margin: 32px 0 16px;
    }
    .admin_info_box {
      border: 1px solid #ebecef;
      padding: 12px 16px;
  
      .admin_info_img {
        margin-right: 20px;
  
        & > img {
          width: 32px;
          height: 32px;
          object-fit: cover;
        }
      }
  
      .admin_info_name {
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #21252e;
        letter-spacing: 0;
        line-height: 14px;
        margin-bottom: 4px;
      }
  
      .admin_info_concact {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #505363;
        letter-spacing: 0;
        line-height: 12px;
      }
      .admin_tag {
        background: #ecf2fd;
        border-radius: 10px;
        border: 1px solid #86b0f7;
        color: #3377ff;
        height: 18px;
        line-height: 16px;
        margin-left: 8px;
      }
    }
    .ant-input-sm {
      height: 24px;
      line-height: 24px;
    }
  }
  
  .default_tag {
    border-radius: 12px;
    background: #ebecef;
    border: 1px solid #d4d9e2;
    height: 18px;
    line-height: 16px;
  }
  
  .company_table {
    margin-top: 8px;
    padding: 12px 0 16px;
    background-color: #fff;
  }
  
  .company_table_css {
    margin-top: 16px;
    padding: 0 24px;
  }
  
  .companyEdit {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #00b259;
    line-height: 14px;
  }
  
  .replace_admin {
    position: absolute;
    top: 51%;
    right: 22px;
    font-size: 18px;
  }
  
  .company_basicInfo {
    .ant-descriptions-item .ant-descriptions-item-label {
      overflow: hidden !important;
      line-height: 16px;
    }
    .ant-descriptions-item .ant-descriptions-item-content {
      white-space: nowrap;
      display: inline-block;
      overflow: hidden;
      width: 70%;
      text-overflow: ellipsis;
      line-height: 16px;
    }
  }
  
  .select_box {
    display: inline-block;
    position: relative;
    top: 1px;
    .search_input_box {
      height: 32px;
      width: 100%;
      .ant-input {
        height: 32px;
        padding-right: 70px;
      }
    }
    .select_list {
      max-height: 254px;
      overflow-y: scroll;
      .ant-list-item {
        padding: 0;
        margin-top: 16px;
        cursor: pointer;
      }
      .ant-list-item:first-child {
        margin-top: 0;
      }
      .ant-list {
        line-height: 14px;
      }
      .demo {
        position: absolute;
        bottom: 16px;
        width: 100%;
        text-align: center;
        .ant-spin-dot-spin {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
  
}
