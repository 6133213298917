.searchIcon {
  font-size: 16px;
  color: #8c97b2;
  cursor: pointer;
}
.closeButtonIcon {
  font-size: 16px;
  color: #8c97b2;
  padding-right: 8px;
}

.searchInput.ant-input:not(:last-child) {
  padding-right: 70px;
}

