.breadcrumb {
  width: 100%;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(33, 37, 46, 0.12);
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 24px;
  line-height: 48px;
  z-index: 10;
  display: flex;
  align-items: center;
  .back-icon {
    color: #00b259 !important;
    margin-right: 8px;
    font-size: 16px;
  }
  .bread_title {
    font-size: 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #21252e;
    flex: 1;
  }
  .actions-box{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}


