.admin_menus {
  padding: 16px 24px;
  .alert {
    .ant-alert-with-description.ant-alert-no-icon {
      padding: 10px 16px;
    }
    .ant-alert-with-description .ant-alert-message {
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
    }
    .ant-alert-success {
      background: #E7F7EF;
      border: 1px solid #afe6ca;
    }
    .ant-alert-description {
      font-size: 14px;
      line-height: 20px;
    }
    .ant-alert-with-description .ant-alert-close-icon {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .container {
    height: calc(~'100vh - 274px');
    border: 1px solid #F5F7FA;
    padding: 16px;
    margin-top: 16px;
    overflow-y: auto;
    .tree_tag {
      height: 18px;
      margin-left: 16px;
      font-size: 12px;
      padding: 0 8px;
      border-radius: 10px;
      border: 1px solid;
      line-height: 16px;
    }
    .green {
      color: #00b259;
      border-color: #afe6ca;
      background: #e7f7ef;
    }
    .blue {
      color: #3377ff;
      border-color: #3377FF;
      background: #3377ff1a;
    }
    .ant-tree {
      .ant-tree-treenode, .ant-tree-switcher, .ant-tree-draggable-icon{
        
        line-height: 50px;
      }
      .ant-tree-treenode, .ant-tree-node-content-wrapper{
        height: 100%;
        line-height: 50px;
      }
      li {
        padding: 0;
        height: 50px;
        &>span {
          height: 100%;
          line-height: 50px;
        }
      }
      li span .ant-tree-iconEle {
        line-height: 50px;
        margin-right: 6px;
      }
      li ul {
        padding: 0;
        li {
          padding-left: 18px;
        }
      }
    }
  }
  .container::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
  }
  .container::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 6px;
    background: #EBECEF;
  }
}

.create_menus {
  margin-top: 50px;
  padding-top: 32px;
  .title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #21252E;
    line-height: 20px;
    padding-left: 8px;
    border-left: 3px solid #00B259;
  }
  .tips {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8C97B2;
    line-height: 20px;
    margin-bottom: 24px;
  }
}

.menu_details {
  padding: 16px 24px;
  .desc {
    margin-top: 12px;
    &>.desc_title {
      font-size: 14px;
      font-weight: bold;
      color: #21252E;
      line-height: 16px;
      margin-bottom: 8px;
    }
    &>.desc_content {
      font-size: 12px;
      font-weight: 400;
      color: #21252E;
    }
  }
}
