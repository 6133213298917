.policy_item {
  height: 40px;
  background: #f5f7fa;
  overflow: hidden;
  padding: 0 14px 0 24px;
  margin-bottom: 8px;
  & > .item_action {
    display: flex;
  }
  .delete {
    margin-left: 21px;
    color: #e60000;
  }
}

