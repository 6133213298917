.layout-body {
  width: 100%;
  height: 100vh;
  .layout-header {
    width: 100%;
    height: 44px;
    background-color: #fff;
    box-shadow: 0px 1px 0px 0px rgba(33, 37, 46, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo-wrap {
      height: 44px;
      padding-left: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo-img {
        max-height: 100%;
      }
    }
    .user-wrap {
      padding-right: 16px;
    }
  }
  section {
    height: calc(100vh - 44px);
    display: flex;
  }
  aside {
    width: 224px;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(34, 37, 43, 0.12);
    position: relative;
    z-index: 2;
  }
  main {
    flex: 1;
    padding: 0;
    position: relative;
    overflow: auto;
    background-color: #ffffff;
  }
  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 24px !important;
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-sub-menu,
  .ant-menu-inline .ant-menu-submenu-title {
    margin: 0;
  }
  .ant-layout-header {
    padding: 0 16px;
  }
  .org-name {
    margin: 0 10px;
    display: inline-block;
    max-width: 188px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 18px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #21252e;
    letter-spacing: 0.16px;
    cursor: pointer;
    position: relative;
    top: 4px;
  }
  .org-logo {
    position: relative;
    top: -2px;
  }
  .org-icon {
    font-size: 16px;
    position: relative;
    top: 2px;
  }
}

.user-name,
.user-name:hover {
  color: #9ea5b2 !important;
  background-color: #fff !important;
  .ant-dropdown-menu-title-content {
    display: inline-block;
    max-width: 188px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.home-page {
  height: 30px !important;
  line-height: 30px !important;
  border-bottom: 1px solid #ebecef;
}

.ant-layout-content {
  position: relative;
  scrollbar-color: #ebecef; //设置滚动条颜色
  scrollbar-width: thin; //设置滚动条宽度
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 6px;
    background: #ebecef;
  }
}

