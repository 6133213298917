.rc_transfer {
  display: flex;
  &>.rc_transfer_table {
    width: 466px;
    &>.rc_transfer_title {
      color: #21252e;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
      font-family: PingFangSC-Medium, PingFang SC;
    }
    .ant-table .ant-table-body, .ant-table .ant-table-fixed {
      border: none;
    }
    .ant-table {
      border-left: 1px solid #ececee;
      border-right: 1px solid #ececee;
      border-bottom: 1px solid #ececee;
    }
    .ant-table-scroll {
      border-top: 1px solid #ececee;
    }
    .ant-table-fixed-header .ant-table-scroll .ant-table-header {
      border-left: none;
      margin-right: -17px;
    }
    .ant-table-body::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 6px;
    }
    .ant-table-body::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 6px;
      background: #EBECEF;
    }
    .ant-table-header.ant-table-hide-scrollbar {
      margin-bottom: -20px !important;
    }
    .ant-table-fixed-header .ant-table-scroll .ant-table-header {
      padding-bottom: 10px;
    }
    .ant-table table>tbody.ant-table-tbody tr>td {
      padding: 10px 16px;
      line-height: 12px;
      white-space: nowrap;
    }
    .ant-table table>thead.ant-table-thead th {
      padding: 9px 16px;
      line-height: 12px;
    }
  }
  &>.right_table {
    :global {
      .ant-table .ant-table-thead > tr > th:first-child,
      .ant-table .ant-table-tbody > tr > td:first-child {
        padding-left: 40px;
      }
      .ant-table table > thead.ant-table-thead th {
        padding: 11px 16px;
        line-height: 12px
      }
    }
  }
  &>.rc_transfer_icon {
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &>.rc_icon_left {
      margin-top: 30px;
      transform: rotate(180deg);
    }
    &>.rc_icon {
      font-size: 12px;
      color: #A4AAB7;
    }
  }
  .no_data_css {
    width: 100%;
    margin: auto;

    .box_position {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    span {
      color: #595954;
    }

    a {
      color: #00b259;
    }
  }
  .ant-table-pagination.ant-pagination {
    margin: 0;
    border: 1px solid #EBECEF;
    border-top: 0;
    position: relative;
  }
  .ant-pagination-total-text {
    position: absolute;
    left: 12px;
  }
  .ant-pagination-next {
    margin-right: 12px !important;
  }
}

.rc_transfer_btn {
  position: absolute;
  bottom: 32px;
  right: 40px;
}
