.role_select {
  
  .ant-tag {
    height: 18px;
    line-height: 16px;
    background: #e7f7ef;
    border-radius: 10px;
    border: 1px solid #afe6ca;
    margin-bottom: 8px;
    .anticon-close {
      color: #afe6ca;
    }
  }
  .tree_tag {
    .tag:last-child {
      margin-right: 0;
    }
  }
  
  .add_auth_role {
    font-size: 16px;
    vertical-align: middle;
  }
  .search_box {
    height: 36px;
    border: 1px solid #d4d9e2;
    padding: 0 32px 0 12px;
    position: relative;
    cursor: pointer;
    .search_box_text {
      font-size: 14px;
      height: 34px;
      line-height: 34px;
      display: flex;
      align-items: center;
      .search_box_item {
        background: #eaedf3;
        border-radius: 10px;
        border: 1px solid #d4d9e2;
        padding: 2px 8px;
        margin-right: 8px;
        height: 18px;
        line-height: 12px;
        font-size: 12px;
      }
    }
    .search_icon_down {
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translateY(-50%);
      svg {
        transition: all 0.2s;
      }
    }
    .search_icon_up {
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translateY(-50%);
      svg {
        transition: all 0.2s;
        transform: rotate(180deg);
      }
    }
  }
}
.role-overlay-box{
  .ant-dropdown-menu {
    min-width: 300px;
    max-width: 500px;
    background: #ffffff;
    box-shadow: 0px 0px 1px 0px rgba(34, 37, 43, 0.2),
      0px 4px 10px 0px rgba(34, 37, 43, 0.24);
    padding: 16px 0;
  }
  .ant-checkbox-wrapper {
    width: 100%;
  }
  .ant-list {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #505363;
    line-height: 20px;
  }
  .ant-list-header {
    padding: 16px 0 8px;
  }
  .ant-list-split .ant-list-item,
  .ant-list-split .ant-list-header {
    border-bottom: none;
  }
  .ant-list-item:hover {
    background: #f9f9fb;
  }
  .role_list_select {
    padding: 0 16px;
    .ant-list-items {
      overflow-y: auto;
      max-height: 260px;
    }
    .ant-list-items::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 6px;
    }
    .ant-list-items::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 6px;
      background: #cbd1de;
    }
    .ant-skeleton-paragraph li {
      width: 100% !important;
    }
  }
  .role_list {
    padding: 0 16px;
  }
}

