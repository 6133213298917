.mermber {
  display: flex;
  justify-content: space-between;
}

.left-side {
  width: 224px;
  height: 100vh;
  background: #f9f9fb;
  padding: 16px 0;
  margin-top: -16px;
  margin-left: -24px;
}

.input-search {
  padding: 0 8px;
  margin-bottom: 16px;
  .ant-input-affix-wrapper {
    border: none;
    border-bottom: 1px solid #d4d9e2;
  }
  .ant-input-affix-wrapper-disabled {
    background-color: transparent;
  }
}

.item {
  padding: 11px 8px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #505363;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item-org {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9ea5b2;
  line-height: 12px;
}

.item-active {
  color: #00b259;
  background-color: #f2f2f5;
  padding-left: 20px;
}

.right-side {
  width: calc(~'100% - 224px');
}

.member-title {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #21252e;
  line-height: 20px;
  margin-bottom: 16px;
  position: relative;
  padding-left: 10px;
  &::before {
    content: ' ';
    height: 16px;
    width: 3px;
    background-color: #00b259;
    position: absolute;
    left: 0;
    top: 2px;
  }
}

.member-list {
  display: flex;
  justify-content: space-between;
  .member-list-search {
    width: 316px;
  }
}

.add_member_link {
  color: #00b259;
  margin-top: -20px;
  margin-bottom: 24px;
  cursor: pointer;
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 14px;
}

.check_total_wrap {
  height: 40px;
  background-color: #f5f7fa;
  line-height: 40px;
  padding: 0 24px;
  margin-top: 16px;
  span {
    color: #00b259;
  }
  .handle_remove_link {
    color: #e60000;
    float: right;
    cursor: pointer;
  }
  .handle_link {
    cursor: pointer;
    margin-left: 8px;
  }
}

.remove_user_list {
  padding: 8px;
  margin: 8px 0 4px;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-color: #ebecef; //设置滚动条颜色
  scrollbar-width: thin; //设置滚动条宽度
  .remove_user_item {
    height: 40px;
    border: 1px solid #979797;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    margin: 8px 0;
    text-align: left;
    .remove_user_item_name {
      width: 60%;
      text-align: left;
    }
    .remove_user_item_displayname {
      flex: 1;
    }
    i:hover {
      color: #e60000;
      cursor: pointer;
    }
  }
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 6px;
    background: #ebecef;
  }
}

.member-number {
  padding: 16px 0;
  font-size: 14px;
  color: #505363;
  line-height: 1;
  font-family: PingFangSC-Medium, PingFang SC;
}

.ant-input-group-addon .ant-btn {
  background-color: #fff !important;
  border-color: #d4d9e2 !important;
  outline: none !important;
}

.ant-input-group-addon .ant-btn-icon-only {
  min-width: 48px !important;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: 0 !important;
}
